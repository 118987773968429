<template>
  <a-card title="新合同统计" size="small">
    <template slot="extra">
      <a-range-picker
        v-model="dateRange"
        format="YYYY-MM"
        size="small"
        :allowClear="false"
        style="margin: -2px 0"
        @change="queryData"
      />
    </template>
    <div id="ContractStatsChart" style="height: 260px"></div>
  </a-card>
</template>

<script>
import { dashboardContractStats } from "@/apis/stats";
import { Line } from "@antv/g2plot";
import moment from "moment";

export default {
  data() {
    return {
      dateRange: [moment().subtract(5, "months"), moment()],
      chartPlot: null,
    };
  },
  methods: {
    initData() {
      this.chartPlot = new Line("ContractStatsChart", {
        data: [],
        xField: "date",
        yField: "count",
        label: { position: "middle" },
        color: "orange",
        meta: { count: { alias: "数量" } },
      });
      this.chartPlot.render();
      this.queryData();
    },
    queryData() {
      const startDate = this.dateRange[0].clone().startOf("month");
      const endDate = this.dateRange[1].clone().add(1, "months").startOf("month");

      dashboardContractStats({
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
      }).then((data) => {
        data = data.map((item) => ({ ...item, date: moment(item.date).format("YYYY-MM") }));

        const dataItems = [];
        const diffMonths = endDate.diff(startDate, "months");
        for (let index = 0; index < diffMonths; index++) {
          const date = this.dateRange[0].clone().add(index, "months").format("YYYY-MM");
          const findIndex = data.findIndex((item) => item.date === date);
          if (findIndex === -1) {
            dataItems.push({ date, count: 0 });
          } else {
            dataItems.push(data[findIndex]);
          }
        }

        this.chartPlot.changeData(dataItems);
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
